import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { RichText } from "prismic-reactjs"
import styled from "styled-components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
const Content2 = styled.div`
  margin: 0 auto 0 auto;
  a {
    color: #6594d4;
  }
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { arrival } = this.props.data.arrival.allAbouts.edges[0].node
    if (typeof window !== "undefined") {
      require("smooth-scroll")('a[href*="#"]', {
        offset: 80,
      })
    }

    return (
      <Layout location={this.props.location.pathname}>
        <SEO
          title="GLOBART ACADEMY "
          description="Die GLOBART Academy Triade LEBEN-MACHT-SINN!"
          keywords={[
            "GLOBART Academy",
            "Interaktiv",
            "Friedrich von Borries",
            "Gedankenexperiment",
            "Demokratie",
          ]}
        />
        <Content2>
          <h1>Anfahrt</h1>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2657.813873604954!2d15.33131611565221!3d48.229458479230864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477264473ec4b5af%3A0xabf949a4b96c1bc1!2sStift%20Melk!5e0!3m2!1sde!2sat!4v1619251010943!5m2!1sde!2sat"
            width="100"
            height="450"
            allowfullscreen=""
            style={{ border: "0", width: "100%", height: "36vw" }}
            loading="lazy"
          ></iframe>
          <div id="arrival">{RichText.render(arrival)}</div>
          <p><b>Covid-Informationen:</b></p>

          <p>Um die größtmögliche Sicherheit aller Mitwirkenden und Teilnehmenden zu
          erzielen, ersuchen wir alle - auch Geimpfte und Genesene - um einen gültigen
          Testnachweis. Das heißt für Sie konkret:</p>
          <p>Für den Einlass zur GLOBART Academy brauchen Sie in jedem Fall (d.h. auch bei geltender 3G-Regel) einen gültigen negativen Testnachweis (PCR-Test oder Antigentest. Antigen-Selbsttests sind ausgeschlossen.)
          Wir bitten Sie darum Ihre Tests so vorzunehmen, dass Sie (sinnvollerweise) für die Dauer Ihres Aufenthalts bei der Academy gelten. (Im Fall des Ablaufs während des Aufenthalts bitten wir um ein Test-Update.) Die Testergebnisse werden von unserem Einlasspersonal zusammen mit Ihrem Personalausweis täglich kontrolliert. Bei ungültigem/nicht vorliegendem Test verweisen wir auf Testmöglichkeiten in Melk.
          </p>
          <p><b>Testen in Melk:</b></p>
          <p>- Covid Zentrum Melk, Linzerstraße 8. Öffnungszeiten: Montag bis Samstag 8-20 Uhr. Infos unter https://covid-zentrum-melk.at/index.php, sowie https://notrufnoe.com/testung/ (Registrierung empfohlen!)</p>
          <p>- Testung auch in einigen Apotheken vor Ort möglich; Registrierung erforderlich unter https://apotheken.oesterreich-testet.at/#/registration/start</p>
          <p>z.B.</p>
            <p>Landschaftsapotheke Melk/Donau am Rathausplatz 10 A-3390 Melk</p>
            <p>Tel.: 02752-52315-0</p>
            <p>Fax.: 02752-52315-17</p>
            <p>E-Mail: landschaftsapo.melk@aon.at</p>
            <p>Mo-Fr: 08:00-18:00</p>
            <p>Sa: 08:00-12:00</p>
            <p>Kontakt bei Covid-Fragen zur Veranstaltung</p>
            <p>E-Mail: helena.kersting@globart.at</p>
            <p>Telefon: 0676 841 090 333</p>
            <p><b>Ablauf</b></p>
            <p>In den Innenräumen des Stifts gilt Maskenpflicht (FFP2 oder OP-Mund-Nasen-Schutz). Bitte bedecken Sie Mund und Nase. Unsere Mitarbeiter sorgen durch konsequente Lüftung der Veranstaltungsräume für regelmäßigen Luftaustausch. Beim Aufenthalt außerhalb des Gebäudes ist keine Maske nötig, bitte achten Sie hier besonders auf ausreichend Abstand zu umliegenden Personen. An nahezu allen Orten stehen Desinfektionsspender bereit. Bitte nutzen Sie diese und vergessen Sie nicht das regelmäßige Händewaschen. Zu den Essenszeiten werden auszufüllende Registrierungsbögen ausliegen. Ihre Daten werden 28 Tage nach Ablauf der Veranstaltung gelöscht und zu keinem anderen Zweck als der Nachverfolgung im Covid-Fall verwendet.</p>
            <p>Bitte folgen Sie den Anweisungen unserer Mitarbeiter sowie Aushänge um gemeinsam mit uns einen reibungslosen Ablauf der Veranstaltung zu ermöglichen. Wir geben uns die größte Mühe um Ihre Sicherheit und auch die Dritter zu gewährleisten. Vielen Dank für Ihre Unterstützung!</p>
        </Content2>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query ArrivalQuery {
    arrival: prismic {
      allAbouts(lang: "de-at") {
        edges {
          node {
            arrival
          }
        }
      }
    }
  }
`
